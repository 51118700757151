body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(35, 54, 54);
  color: whitesmoke;
  height: 100svh;
  width: 100svw;
  overflow: hidden;
  max-height: 100svh;
  max-width: 100svw;
}

code {
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
 html {
  font-size: 16px;
  height: 100svh;
  width: 100svw;
  overflow: hidden;
  max-height: 100svh;
 }