.about_us_cntnr {
    animation: fadeIn 5s forwards;
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}